/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/select2@4.1.0-rc.0/dist/css/select2.min.css
 * - /npm/animate.css@3.7.2/animate.min.css
 * - /npm/swiper@4.1.0/dist/css/swiper.min.css
 * - /npm/flag-icon-css@3.5.0/css/flag-icon.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
